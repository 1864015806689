import palette from '../theme/palette';

const getColors = () => {
  const colors = [
    palette('light').primary.lighter,
    palette('light').primary.light,
    palette('light').primary.main,
    palette('light').primary.dark,
    palette('light').primary.darker
  ];
  colors.forEach((color, index) => colors.splice(index, 1, color.slice(1).toString()));
  return colors.toString();
};

type Model = 'marble' | 'beam' | 'pixel' | 'sunset' | 'ring' | 'bauhaus';

export const boringAvatarGenerator = (text: string, model: Model) => {
  const encodedText = encodeURIComponent(text);
  return `https://source.boringavatars.com/${model}/20/${encodedText}?colors=${getColors()}`;
};
