// hooks
import { boringAvatarGenerator } from 'src/utils/boringAvatars';
import { useAuthContext } from 'src/auth/useAuthContext';
// utils
import createAvatar from '../utils/createAvatar';
//
import { CustomAvatar } from './custom-avatar';
import { AvatarProps } from '@mui/material';

// ----------------------------------------------------------------------

type AvatarColor = 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

// ----------------------------------------------------------------------

export interface Props extends AvatarProps {
  color?: AvatarColor;
}

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: Props) {
  const { user } = useAuthContext();

  return (
    <CustomAvatar
      src={user?.photoURL || boringAvatarGenerator(user?.displayName, 'bauhaus')}
      alt={user?.displayName}
      color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </CustomAvatar>
  );
}
